<spot-drop-modal
  [opened]="opened"
  alignment="bottom-right"
  (closed)="opened = false"
  class="op-baseline-modal"
>
  <button
    slot="trigger"
    type="button"
    class="button"
    (click)="toggleOpen()"
    [title]="text.toggle_title"
    data-qa-selector="baseline-button"
  >
  <span class="spot-icon spot-icon_baseline"></span>
    {{ text.toggle_title }}
  
    <span *ngIf="!opened" class="spot-icon spot-icon_dropdown"></span>
    <span *ngIf="opened" class="spot-icon spot-icon_dropdown-open"></span>
  </button>

  <ng-container slot="body">
    <div class="op-baseline-modal--header">
      <span class="spot-body-small">{{ text.header_description }}</span>
    </div>
  </ng-container>
  <op-baseline
    [showActionBar]="true"
    slot="body"
    (submitted)="opened = false"
  ></op-baseline>
</spot-drop-modal>
